<template>
	<div class="container">
		<div class="zoology-CN" v-if="language">
			<div class="zoology-div1">
				<img src="../../assets/img/zoology/1.png" alt="" />
				<img class="zoology-1-1" src="../../assets/img/zoology/CN-icon.png" @click="swiperLanguage" alt="" />
			</div>
			<div class="zoology-div2">
				<img src="../../assets/img/zoology/2.png" alt="" />
			</div>
			<div class="zoology-div3">
				<img src="../../assets/img/zoology/3.png" alt="" />
			</div>
			<div class="zoology-div4">
				<img src="../../assets/img/zoology/4.png" alt="" />
				<a @click="handleLinkClick" :href="yourLink">
					<img class="zoology-4-1" src="../../assets/img/zoology/4-1.png" alt="" />
				</a>
			</div>
		</div>
		<div class="zoology-EN" v-else>
			<div class="zoology-div1">
				<img src="../../assets/img/zoology/11.png" alt="" />
				<img class="zoology-1-1" src="../../assets/img/zoology/EN-icon.png" @click="swiperLanguage" alt="" />
			</div>
			<div class="zoology-div2">
				<img src="../../assets/img/zoology/22.png" alt="" />
			</div>
			<div class="zoology-div3">
				<img src="../../assets/img/zoology/33.png" alt="" />
			</div>
			<div class="zoology-div4">
				<img src="../../assets/img/zoology/44.png" alt="" />
				<a @click="handleLinkClick" :href="yourLink">
					<img class="zoology-44-1" src="../../assets/img/zoology/44-1.png" alt="" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				language: true,
				yourLink: 'https://forms.gle/PhBvzZbVxxg5EpUy8'
			}
		},
		created() {
			
		},
		mounted() {
			
		},
		methods: {
			swiperLanguage() {
				this.language = !this.language;
			},
			// 使用外部浏览器打开
			handleLinkClick(event) {
				event.preventDefault(); 
				window.open(this.yourLink, '_system'); 
			}
		},
	}
</script>

<style lang="scss" scoped>
	img {
		width: 100%;
		display: block;
		margin: 0;
		margin-top: -1px;
		padding: 0;
	}
	.zoology-CN {
		
	}
	.zoology-div1, .zoology-div4 {
		position: relative;
	}
	.zoology-1-1 {
		position: absolute;
		width: 30%;
		top: 0.4rem;
		right: 5%;
	}
	.zoology-4-1 {
		display: block;
		width: 80%;
		position: absolute;
		top: 4rem;
		left: 10%;
	}
	.zoology-44-1 {
		display: block;
		width: 80%;
		position: absolute;
		top: 3.8rem;
		left: 10%;
	}
</style>